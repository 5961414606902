@import './sass/settings';
/* reset */
@charset "UTF-8";

.call__mute, .call__screen-pop, .call__hang-up {
  border: 0px;
  color: $primary-button-text-color;
  font-size: 20px;
  font-weight: 700;
  height: 49px;
  text-align: center;
  width: 150px;
}
.call__hang-up {
  background-color: #b00;
}
.call__screen-pop, .call__mute {
  background-color: $primary-button-text-color;
  color: $primary-color;
}

@import '../sass/settings';
.badges {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.badge {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 200px;
  justify-content: center;
  text-align: center;
  width: 200px;
}
.badge-img {
  color: $border-color;
  font-size: 60px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  vertical-align: middle;
  width: 100px;
}
.badge-img.fa {
  line-height: 100px;
}
.badge-title {
  font-weight: 700;
}
.badge-description {
  font-size: 14px;
}

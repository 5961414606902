$primary-color: #00103b;
$secondary-color: #03d8ee;
$tertiary-color: #0f7eee;
$quaternary-color: #8c4cf9;
$quintenary-color: #f4e4c1;
$primary-button-text-color: #ffffff;
$primary-button-hover-color: #0a2540;
$error-color: #d53e8e;
$background-color: #f6f9fc;

$border-color: #dddddd;
$secondary-border-color: #888888;

$screen-xsm: 0;
$screen-sm: 400px;
$screen-md: 480px;
$screen-md-lg: 650px;
$screen-lg: 768px;
$screen-xlg: 1024px;
$screen-xxlg: 1200px;
$screen-xxxlg: 1680px;
$screen-xxxxlg: 1680px;

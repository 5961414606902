.dialer {
  text-align: center;
  width: 165px;
}
.dialer__input-line {
  display: flex;
}
.dialer__input {
  border-right: 0;
}
.dialer__back {
  background-color: #fff;
  border-left: 0;
}
.keypad {
  display: grid;
  list-style: none;
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
}
.keypad li {
  display: flex;
  justify-content: center;
}
.call__button-row {
  display: flex;
  justify-content: space-between;
}

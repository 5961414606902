@import 'settings';
/* reset */
@charset "UTF-8";

.dialer__btn {
  border-color: $primary-color;
  border-radius: 30px;
  color: $primary-color;
  font-size: 20px;
  height: 49px;
  line-height: 2.00;
  margin: 5px;
  padding: 5px 0;
  text-align: center;
  width: 49px;
}

.dialer__btn-decline, .dialer__btn-change-status {
  border-color: $primary-color;
  border-radius: 30px;
  color: $primary-color;
  font-size: 20px;
  height: 49px;
  line-height: 2.00;
  margin: 5px;
  padding: 5px 10px;
  text-align: center;
}

.dialer__btn:hover {
  background-color: $primary-color;
  color: #FFFFFF;
}

.dialer__btn-sub-text {
  display: block;
  padding-top: 2px;
  font-size: 10px;
  color: #787878;
}

.green {
  background-color: #00FF00;
}
.red {
  background-color: #FF0000;
}
.job__id, .component__id {
  display: none;
}
.call-container {
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}
.company-site {
  height: 100%;
  width: 100%;
}
.active-call {
  background-color: $border-color;
  bottom: 0;
  left: 0;
  padding-top: 200px;
  position: relative;
  text-align: center;
  width: 270px;
  z-index: 999;
}
.active-call__row {
  width: 100%;
}
.active-call__header {
  display: flex;
}
.call__accept, .call__decline, .call__next, .call__finish {
  border: 0px;
  border-radius: 30px;
  color: $primary-button-text-color;
  font-size: 20px;
  font-weight: 700;
  height: 49px;
  margin: 10px 20px;
  opacity: 100%;
  text-align: center;
  width: 150px;
}
.call__make {
  border: 0px;
  border-radius: 30px;
  color: $primary-button-text-color;
  font-size: 20px;
  font-weight: 700;
  height: 45px;
  margin: 5px;
  text-align: center;
  width: 100px;
}
.call__accept-processing {
  display: inline-block;
  height: 49px;
  margin: 10px 20px;
  text-align: center;
  width: 150px;
}
.call__accept, .call__make {
  background-color: green;
}
.call__decline {
  background-color: #b00;
}
.call__next, .call__finish {
  background-color: $primary-button-text-color;
  color: $primary-color;
  display: none;
}
.call__company-component {
  font-size: 24px;
  font-weight: 700;
}
.call.is-active .call__accept {
  background-color: #b00;
}
.component__icon, .job__icon {
  margin-right: 5px;
}

@charset "UTF-8";
@import '../sass/settings';
.scratcher-ticket {
  width: 785px;
}
.scratcher {
  display: flex;
  flex-wrap: wrap;
  height: 600px;
  width: 600px;
}
.scratcher-ticket__title {
  font-size: 28px;
  font-weight: 700;
  padding: 10px 0;
  text-align: center;
}
.scratcher-ticket__border {
  background: radial-gradient(circle, #eaf199 10%, #956f1c 30px, $background-color 30%);
  background-repeat: repeat;
  background-size: 87px 87px;
  //background: linear-gradient(.5turn, #600, #b00, #600);
  display: inline-flex;
  height: 785px;
  padding: 87px;
  width: 785px;
}
.secret-content {
  background-color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.scratch-off-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.scratch-off-canvas {
  cursor: url('/images/coin.png') 14 14, auto;
  position: absolute;
}

@import './sass/settings';
/* reset */
@charset "UTF-8";

.dispositions {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
.dispositions::before {
  background-color: $border-color;
  bottom: 0px;
  content: "";
  left: 0px;
  opacity: 90%;
  position: absolute;
  right: 0px;
  top: 0px;
}
.dispositions-form.form {
  margin: 100px auto 0;
  position: relative;
  width: 500px;
}
.dispositions__select {
  display: inline-block;
  margin-right: 5px;
  width: auto;
}
.dispositions__submit {
  background-color: $quaternary-color;
  border-radius: 16px;
  color: $primary-button-text-color;
  cursor: pointer;
  font-weight: 425;
  margin-top: 5px;
  padding: 5px 16px;
}
.dispositions__row-select {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.dispositions__processing {
  margin-top: 5px;
}

@import '../sass/settings';
@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

.repeat {
  background-color: $tertiary-color;
  background-image: radial-gradient(circle at center, $background-color, $tertiary-color);
  border: 15px solid #000;
  border-radius: 25px;
  font-weight: 700;
  font-size: 24px;
  height: 200px;
  text-align: center;
  vertical-align: middle;
  width: 200px;
}

.icons { 
  background: #fff url('/images/slotwheel.png') repeat-y;
  display: inline-block;
  height: 564px;
  overflow: hidden;
  padding: 0 80px;
  position: relative;
  top: -94px;
  transform: translateZ(0);
  transition: 0.3s background-position ease-in-out;
  width: 180px;
  will-change: backgroundPosition;
}

.legend-line {
  align-items: center;
  display: flex;
  font-size: 20px;
}
.legend-win {
  margin-left: 5px;
}
.legend-icon {
  background: #fff url('/images/slotwheel-small.png');
  display: inline-block;
  height: 60px;
  width: 60px;
}
.legend-cherry {
  background-position-y: 0px;
};
.legend-bell {
  background-position-y: -60px;
};
.legend-watermelon {
  background-position-y: -120px;
};
.legend-diamond {
  background-position-y: -180px;
};
.legend-7 {
  background-position-y: -240px;
};
.legend-star {
  background-position-y: -300px;
};
.legend-orange {
  background-position-y: -360px;
};
.legend-bar {
  background-position-y: -420px;
};
.legend-grape {
  background-position-y: -480px;
};

.icons:nth-child(2) {
  margin: 0 10px;
}

.gradient-fade {
  background: linear-gradient(
    to bottom,
    rgba(00,00,00,1) 0%,
    rgba(33,33,33,0) 25%,
    rgba(33,33,33,0) 75%,
    rgba(00,00,00,1) 100%
  );
  bottom: 94px;
  left: 0;
  position: absolute;
  right: 0;
  top: 94px;
}

.ball-container {
  position: relative;
  width: 50px;
  height: 50px;
}
.ball {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%;
  position: relative;
}
.ball:before {
  content: "";
  position: absolute;
  background: radial-gradient(circle at 50% 120%,  rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  bottom: 2.5%;
  left: 5%;
  opacity: 0.6;
  height: 100%;
  width: 90%;
  -webkit-filter: blur(5px);
  z-index: 2;
}
.ball:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 5%;
  left: 10%;
  border-radius: 50%;
  background: radial-gradient(circle at 50% 50%,  rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) 14%, rgba(255, 255, 255, 0) 24%);
  -webkit-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -moz-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -ms-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -o-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -webkit-filter: blur(10px);
}

.ball .shadow {
position: absolute;
width: 100%;
height: 100%;
background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
-webkit-transform: rotateX(90deg) translateZ(-150px);
-moz-transform: rotateX(90deg) translateZ(-150px);
-ms-transform: rotateX(90deg) translateZ(-150px);
-o-transform: rotateX(90deg) translateZ(-150px);
transform: rotateX(90deg) translateZ(-150px);
z-index: -1;
}

* { box-sizing: border-box; }

.slot {
  display: flex;
}
.spinner-display-border-outer {
  background: radial-gradient(circle, #eaf199 10%, #956f1c 30px, $background-color 30%);
  background-repeat: repeat;
  background-size: 87px 85px;
  //background: linear-gradient(.5turn, #600, #b00, #600);
  display: inline-flex;
  padding: 87px;
}
.spinner-display-border {
  background: linear-gradient(.5turn, #333333, #888, #333333);
  display: inline-flex;
  padding: 15px;
}

.spinner-display {
  background: linear-gradient(.5turn, #956f1c, #eaf199, #956f1c);
  display: inline-flex;
  padding: 10px;
}
.spinner-container {
  display: inline-flex;
  height: 372px;
  overflow: hidden;
  position: relative;
}

.app-wrap.winner-false {
  animation: linear 1s spin;
}

@media screen and (max-width: 1000px) {
  .spinner-container {
    transform: translate(-50%, -50%) scale(.55, .55);
  }
}

@media screen and (max-width: 500px) {
  .spinner-container {
    transform: translate(-50%, -50%) scale(.4, .4);
  }
}

@import 'settings';
@import 'fontawesome/fontawesome';
@import 'fontawesome/solid';
/* reset */
@charset "UTF-8";
html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;}
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block;}
audio,canvas,progress,video{display:inline-block;vertical-align:baseline;}
audio:not([controls]){display:none;height:0;}
[hidden],template{display:none;}
a{background:0 0;}
a:active,a:hover{outline:0;}
abbr[title]{border-bottom:1px dotted;}
b,strong{font-weight:700;}
dfn{font-style:italic;}
mark{background:#ff0;color:#000;}
small{font-size:80%;}
sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}
sup{top:-.5em;}
sub{bottom:-.25em;}
img{border:0;}
svg:not(:root){overflow:hidden;}
hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0;}
pre{overflow:auto;}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em;}
button,input,optgroup,select,textarea{color:inherit;font:inherit;margin:0;}
button{overflow:visible;}
button,select{text-transform:none;}
button,html input[type=button],input[type=reset],input[type=submit]{-webkit-appearance:button;cursor:pointer;}
button[disabled],html input[disabled]{cursor:default;}
button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}
input{line-height:normal;}
input[type=checkbox],input[type=radio]{box-sizing:border-box;padding:0;}
input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{height:auto;}
input[type=search]{-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}
fieldset{border:1px solid silver;margin:0 2px;padding:.35em .625em .75em;}
legend{border:0;}
textarea{overflow:auto;}
table{border-collapse:collapse;border-spacing:0;}
td,th{padding:0;}
*{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;}
blockquote,body,div,figure,footer,form,h1,h2,h3,h4,h5,h6,header,html,iframe,label,legend,li,nav,object,ol,p,section,table,ul{margin:0;padding:0;}
article,figure,footer,header,hgroup,nav,section{display:block;}
label{display:block;padding-bottom:.25em;}
button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}
input,textarea{width:100%;border:1px solid gray;padding:.5rem .65rem;}
input[type=file]{border:0;padding:.5rem 0;}
input[type=number],input[type=search],input[type=password],input[type=email],input[type=tel],input[type=text],input[type=url],textarea{-webkit-appearance:none;}
button,input[type=submit]{padding:.5rem;border:1px solid gray;cursor:pointer;}
input[type=checkbox],input[type=radio]{width:auto;margin-right:.3em;}
input[type=search]{-webkit-appearance:none;border-radius:0;}
input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration{-webkit-appearance:none;}
input[type=number],input[type=search],input[type=password],input[type=email],input[type=tel],input[type=text],input[type=url],textarea,select{display:block;height:35px;width:100%;}
a{text-decoration:none;outline:0;}
/*end reset*/
html {
  scroll-behavior: smooth;
}
.root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.header {
  color: $secondary-color;
  font-size: 20px;
  font-weight: 700;
  height: 50px;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}
.footer {
  background-color: $background-color;
  color: $secondary-border-color;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: space-between;
  line-height: 30px;
  padding: 10px;
  position: relative;
  width: 100%;
}
.header__background {
  background-color: $primary-color;
  bottom: 0;
  left: 0;
  opacity: .5;
  position: absolute;
  right: 0;
  top: 0;
}
.header__links {
  color: $primary-button-text-color;
  font-size: 14px;
  font-weight: normal;
  padding-top: 2px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.header a {
  margin: 0px 5px;
}
.footer a {
  color: $secondary-border-color;
}
a.logo {
  color: $background-color;
  margin: 0;
}
.logo {
  display: inline-block;
  padding-top: 2px;
  position: relative;
  vertical-align: middle;
}
.logo__name {
  font-family: "Century Gothic", CenturyGothic, AppleGothic;
}
.logo__icon {
  background-image: url("/images/logo32.png");
  background-repeat: no-repeat;
  background-size: 26px 26px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  height: 26px;
  line-height: 13px;
  line-height: 13px;
  color: $secondary-color;
  padding-left: 30px;
}
.home-logo {
  width: 300px;
}
.button {
  background-color: $quaternary-color;
  border-radius: 16px;
  color: $primary-button-text-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 425;
  padding: 5px 16px;
  text-align: center;
}
.button-reverse {
  border: 1px solid $quaternary-color;
  background-color: $primary-button-text-color;
  border-radius: 16px;
  color: $quaternary-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 425;
  padding: 5px 16px;
  text-align: center;
}
.button-circle {
  background-color: $quaternary-color;
  border-radius: 50%;
  color: $primary-button-text-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  height: 20px;
  line-height: 18px;
  text-align: center;
  width: 20px;
}
.button-circle-reverse {
  border: 1px solid $quaternary-color;
  background-color: $primary-button-text-color;
  border-radius: 50%;
  color: $quaternary-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  height: 20px;
  line-height: 18px;
  text-align: center;
  width: 20px;
}
.button--big {
  background-color: $quaternary-color;
  border-radius: 25px;
  color: $primary-button-text-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 425;
  padding: 15px 25px;
  text-align: center;
}
@media (min-width: $screen-sm) {
}
@media (min-width: $screen-md) {
}
@media (min-width: $screen-md-lg) {
  .work {
    font-size: 60px;
  }
  .work-adverb {
    width: 195px;
  }
  .home-intro__title {
    font-size: 48px;
  }
  .home-reason__title {
    font-size: 24px;
  }
  .home-reasons__title {
    font-size: 48px;
    line-height: 52px;
  }
  .home-video__question {
    font-size: 28px;
    line-height: 32px;
  }
  .home-how__title {
    font-size: 48px;
    margin-bottom: 52px;
  }
  .home-pay__title {
    font-size: 40px;
    line-height: 44px;
  }
  .home-summary__title {
    font-size: 60px;
  }
}
@media (min-width: $screen-lg) {
  .footer {
    gap: 20px;
    justify-content: center;
  }
}

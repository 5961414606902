@import './sass/settings';
/* reset */
@charset "UTF-8";
.keypad-button {
  background-color: $quaternary-color;
  border-radius: 30px;
  color: $background-color;
  font-size: 20px;
  height: 45px;
  line-height: 1;
  margin: 5px;
  text-align: center;
  vertical-align: middle;
  width: 45px;
}
.keypad-button:hover {
  background-color: $background-color;
  border: 1px solid $quaternary-color;
  border-radius: 30px;
  color: $quaternary-color;
}
.keypad-extra {
  color: $primary-color;
  display: block;
  font-size: 10px;
  text-align: center;
  width: 45px;
}

.keypad-button.green {
  background-color: #36d576;
  color: #fff;
}

.keypad-button.red {
  background-color: #f22f46;
  color: #fff;
}

@import 'base';
body {
  background-color: $background-color;
  color: $primary-color;
  font-size: 16px;
  min-height: 100vh;
}
body.dark-mode {
  background-color: #000;
  color: $primary-button-text-color;
}
body.dark-mode .job, body.dark-mode .component, body.dark-mode .form, body.dark-mode .report-form {
  background-image: linear-gradient(to bottom right, #000, $border-color);
}
body.dark-mode .job .component {
  background: #000;
}
body.dark-mode .content__info, .content__info-full {
  background-color: $border-color;
}
.body-background.reveal {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.ai-phone {
  opacity: .5;
  position: fixed;
  right: 0;
  top: 49px;
}
.ai-head {
  left: 0;
  opacity: .5;
  position: fixed;
  top: 100px;
}
.terms div {
  margin: 10px;
}
.home-page {
  background-color: $primary-color;
}
.home-containers {
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}
.home-container {
  position: relative;
  width: 100%;
}
.home-content__bubble {
  font-size: 18px;
  padding-top: 20px;
  text-align: center;
  width: 100%;
}
.home-content__bubble-1 {
  background-color: $background-color;
}
.home-content__bubble-2 {
  background-color: $primary-color;
  color: $background-color;
}
.home-content__bubble-3 {
  background-image: linear-gradient(to bottom right,$quaternary-color,$secondary-color);
  color: $background-color;
}
.home-bubble__line {
  margin-top: 10px;
}
.home-bubble__line--big {
  display: flex;
  flex-direction: column;
  font-size: 24px;
}
.home-bubble__img {
  margin-top: 20px;
  width: 100%;
}
.home-bubble__icon {
  font-size: 2em;
}
.home-bubble__icon--big {
  font-size: 4em;
}
.home-qa {
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;
}
.home-overview__reasons {
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.home-testimonials {
  display: none;
}
.home-overview {
  height: 100vh;
  line-height: 20px;
  padding: 0px;
  position: relative;
  text-align: left;
  width: 100%;
}
.home-overview__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 20px;
  justify-content: space-evenly;
  position: relative;
  text-align: left;
  width: 100%;
}
.home-summary__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100vh;
  justify-content: center;
  line-height: 20px;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;
}
.home-how__container {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  justify-content: space-evenly;
  position: relative;
  text-align: left;
  width: 100%;
}
.home-intro__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.home-intro-opportunities {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}
.home-intro-content__title {
  color: $tertiary-color;
  font-size: 24px;
  font-weight: 700;
  line-height: initial;
  margin-bottom: 10px;
}
.home-pay-card--left {
  display: flex;
  justify-content: left;
}
.home-pay-card--right {
  display: flex;
  justify-content: right;
}
.home-pay-card__content--left {
  background-color: $background-color;
  border-color: $tertiary-color;
  border-style: solid;
  border-radius: 0 20px 20px 0;
  border-width: 0 2px 2px 0;
  box-shadow: 5px 5px 10px $secondary-color;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  position: relative;
  text-align: left;
  width: 90%;
}
.home-pay-card__content--right {
  background-color: $background-color;
  border-color: $tertiary-color;
  border-style: solid;
  border-radius: 20px 0 0 20px;
  border-width: 0 0 2px 2px;
  box-shadow: -5px 5px 10px $secondary-color;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  position: relative;
  text-align: left;
  width: 90%;
}
.home-how-cards {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  text-align: left;
  width: 100%;
}
.home-how-card {
  background-color: $background-color;
  border-color: $tertiary-color;
  border-radius: 50%;
  border-style: solid;
  border-width: 0 0 2px;
  box-shadow: 5px 5px 10px $secondary-color;
  font-size: 16px;
  height: 300px;
  line-height: 20px;
  padding: 40px 30px;
  text-align: center;
  width: 300px;
  z-index: 1;
}
.home-how-flow {
  position: relative;
}
.home-how-connect {
  background-color: $background-color;
  border-color: $tertiary-color;
  border-style: solid;
  border-width: 0 2px 0 0;
  box-shadow: 5px 5px 10px $secondary-color;
  height: 500px;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 290px;
  width: 10px;
}
.home-pay__img {
  width: 100%;
}
.home-pay__title {
  color: $primary-color;
  font-family: macan,helvetica neue,Helvetica,Arial,sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 5px;
}
.home-pay__description {
  margin: 10px 0;
  line-height: 18px;
}
.home-reasons__title {
  color: $primary-color;
  font-family: macan,helvetica neue,Helvetica,Arial,sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}
.home-reason__title {
  color: $tertiary-color;
  display: inline-block;
  font-family: macan, helvetica neue, Helvetica, Arial, sans-serif;
  font-size: 18px;
  margin: 10px 0;
}
.home-reason__title .fa {
  margin-right: 5px;
}
.home-overview-slideshow {
  height: 100vh;
  overflow: hidden;
  width: 100%;
}
.slideshow__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.slideshow__img--1 {
  background-image: url('/images/slideshow1-1024.png');
}
.slideshow__img--2 {
  background-image: url('/images/slideshow2-1024.png');
}
.slideshow__img--3 {
  background-image: url('/images/slideshow3-1024.png');
}
.slideshow__img--4 {
  background-image: url('/images/slideshow4-1024.png');
}
.slideshow__img--5 {
  background-image: url('/images/slideshow5-1024.png');
}
.home-video {
  text-align: center;
  width: 100%;
}
.home-video__container {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}
.home-video__cover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.home-video__play {
  border-radius: 40px;
  font-size: 24px;
  height: 80px;
  left: 0;
  line-height: 80px;
  margin: -40px auto 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  width: 80px;
}
.home-video__vid {
  width: 100%;
}
.home-video__question {
  color: $tertiary-color;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}
.home-intro-content {
  background-color: $background-color;
  font-size: 18px;
  line-height: 24px;
  padding: 20px;
  width: 100%;
}
.home-content__title {
  color: $secondary-color;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}
.home-content__description {
  color: $tertiary-color;
  line-height: 20px;
  margin-bottom: 10px;
  text-align: left;
}
.home-qa__question {
  border: 1px solid $border-color;
  border-radius: 5px;
  color: $secondary-border-color;
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}
.answer {
  margin-top: 10px;
}
.is-hidden {
  display: none;
}
.home-qa__title {
  color: $tertiary-color;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}
.home-qa__description {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  line-height: 20px;
  margin-bottom: 10px;
  text-align: left;
}
.home-content-main {
  align-items: center;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  line-height: initial;
  margin-top: -100px;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  z-index: 3;
}
.home-content-main__description {
  color: $background-color;
  font-size: 18px;
  margin-bottom: 40px;
  text-align: center;
}
.work {
  color: $background-color;
  display: flex;
  gap: 10px;
  font-size: 28px;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
}
.work-adverb {
  font-style: italic;
  font-weight: 700;
  text-align: center;
  width: 80px;
}
.home-content__title {
  font-size: 36px;
}
.home-intro__title {
  color: $background-color;
  font-size: 24px;
  font-weight: 700;
  line-height: initial;
  text-align: center;
}
.home-summary__title {
  color: $background-color;
  font-size: 26px;
  font-weight: 700;
  line-height: initial;
  text-align: center;
}
.home-how__title {
  color: $background-color;
  font-size: 26px;
  font-weight: 700;
  line-height: initial;
  text-align: center;
}
.home-how__sub-title {
  font-size: 20px;
}
.home-how-card__title {
  color: $tertiary-color;
  font-size: 30px;
  font-weight: 700;
  line-height: initial;
  margin-bottom: 10px;
  text-align: center;
}
.home-content {
  margin: 0 auto;
  width: 60%;
}
.home-pay__container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-around;
}
.home-qa__container {
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.home-content-main__title {
  font-size: 40px;
  margin: 5px 0 25px;
}
.reveal {
  opacity: 0;
}
.reveal.active {
  opacity: 1;
}
/*
.reveal.fade-bottom {
  animation: unfade-bottom 1s ease-in;
}
.reveal.fade-left {
  animation: unfade-left 1s ease-in;
}
.reveal.fade-right {
  animation: unfade-right 1s ease-in;
}
*/
.active.fade {
  animation: fade 1s ease-in;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}
.home-content__bubble-2.reveal.fade.active {
  animation: fade-right 1s ease-in;
}
.home-content__bubble-3.reveal.fade-right.active {
  animation: fade-left 1s ease-in;
}
.slideshow {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.slideshow > li {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* current slide */

.slideshow > li:first-of-type {
  animation-name: unfade;
  animation-delay: 5s;
  animation-duration: 2s;
  z-index: 2;
}

/* next slide to display */

.slideshow > li:nth-of-type(2) {
  z-index: 1;
}

/* all other slides */

.slideshow > li:nth-of-type(n+3) {
  display: none;
}
@keyframes fade {
0% {
  opacity: 0;
}
  100% {
    opacity: 1;
  }
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes unfade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes unfade-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}
@keyframes unfade-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100px);
    opacity: 0;
  }
}
@keyframes unfade-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}
.content .wdp .wdp-container .wdp-date-container ul li.wdp-active::before, .content .wdp .wdp-container .wdp-header, .content .wdp .wdp-container .wdp-hour-container .wdp-hour-el-container input {
  background: $tertiary-color;
}
.content .wdp .wdp-container .wdp-header svg {
  fill: $primary-button-text-color;
}
.content .wdp .wdp-container {
  color: $primary-color;
}
.ai-head-phone {
  opacity: .1;
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -500px;
}
.title {
  border-image: linear-gradient(to right, $tertiary-color, $primary-button-text-color);
  border-image-slice: 1;
  border-bottom: 3px solid;
  display: flex;
  height: 53px;
  justify-content: space-between;
  line-height: 30px;
  padding-left: 20px;
  white-space: nowrap;
  width: 100%;
}
.title h1 {
  line-height: 50px;
}
.nav__header {
  border-color: $secondary-color;
  border-style: solid;
  border-width:  0 0 3px;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  padding: 20px;
}
.general-content {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  min-height: 100vh;
  padding: 70px 20px 20px;
  position: relative;
  z-index: 1;
}
.general-content__title {
  font-size: 20px;
  width: 100%;
}
.general-content__small-title {
  color: $quaternary-color;
  margin-top: 10px;
  width: 100%;
}
.progress-circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: $border-color;
  stroke-width: 13px;
}
.progress-bar {
  stroke: $quaternary-color;
}
.progress {
  border-radius: 100%;
  display: block;
  height: 160px;
  min-width: 160px;
  position: relative;
  width: 160px;
}
.progress-data {
  border-radius: 100%;
  display: block;
  font-size: 2em;
  height: 160px;
  left: 50%;
  margin-top: -55px;
  margin-left: -80px;
  text-align: center;
  position: absolute;
  top: 50%;
  width: 160px;
}
.rewards {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.reward {
  display: flex;
  gap: 5px;
}
.rewards__title {
  align-items: center;
  display: flex;
  font-size: 26px;
  gap: 10px;
}
.rewards__title-icon {
  color: #ffd700;
  font-size: 48px;
}
.reward__icon {
  color: $tertiary-color;
  min-width: 20px;
}
.progress-reward {
  color: $border-color;
  font-size: 48px;
}
.progress-reward--complete {
  color: #ffd700;
}
.progress-percent {
  color: $quaternary-color;
  font-size: 26px;
  margin: 5px 0;
}
.progress-time {
  font-size: 16px;
}
.payment-center, .token-center {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.payment-center__title, .token-center__title {
  color: $tertiary-color;
  font-size: 24px;
}
.payment-center__amount, .token-center__amount {
  font-size: 36px;
}
.card {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: $primary-button-text-color;
  border-color: $border-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  box-shadow: 5px 5px 10px $border-color;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  width: 100%;
}
.cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card__icon {
  color: $tertiary-color;
  font-size: 32px;
}
.card__title {
  align-items: center;
  border-bottom: 1px solid $border-color;
  display: flex;
  font-size: 20px;
  gap: 10px;
  padding-bottom: 10px;
}
.todo {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: $primary-button-text-color;
  border-color: $border-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  box-shadow: 5px 5px 10px $border-color;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
}
.todos {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.todos__hide {
  color: $quaternary-color;
  cursor: pointer;
}
.todos__finished {
  color: $tertiary-color;
  font-size: 26px;
}
.tests {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.test {
  background-color: $primary-button-text-color;
  border-color: $border-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  box-shadow: 5px 5px 10px $border-color;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
}
.todo-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.company {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  gap: 5px;
  height: 20px;
  line-height: 24px;
  white-space: nowrap;
  flex: 1 1 auto;
}
.company__logo {
  height: 18px;
}
.test__info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-weight: 700;
  gap: 8px;
  justify-content: space-between;
  white-space: nowrap;
}
.test__language {
  align-items: center;
  display: flex;
  gap: 5px;
  flex: 0 1 30%;
}
.test__type {
  align-items: center;
  display: flex;
  gap: 5px;
  flex: 0 1 30%;
}
.test__language-flag {
  display: inline-block;
  height: 13px;
  margin-top: -2px;
}
.test__results {
  align-items: stretch;
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding-top: 10px;
}
.test__result {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.test__icon {
  color: $tertiary-color;
  font-size: 18px;
}
.test__title, .todo__header-line {
  color: $tertiary-color;
  display: inline-block;
  font-size: 16px;
  width: 100%;
}
.todo__item {
  display: flex;
  align-items: center;
}
.todo__item--processing {
  background-position: -6px;
  background-size: 36px;
  display: inline-block;
  height: 26px;
  margin-right: 3px;
  width: 26px;
}
.test__description {
  color: $secondary-border-color;
  font-size: 14px;
}
.test__take {
  color: $secondary-border-color;
  font-size: 14px;
  line-height: 31px;
}
.test__link {
  font-size: 16px;
  line-height: initial;
}
.test__icon-score {
  display: flex;
  gap: 8px;
}
.test__score {
  font-size: 24px;
  font-weight: 700;
}
.test__score-icon {
  color: $quaternary-color;
  cursor: pointer;
  font-size: 20px;
  margin-left: 5px;
}
.test__score-practice {
  cursor: pointer;
}
.processing {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><g transform="translate(0 0)"><path d="M50 50L20 50A30 30 0 0 0 80 50Z" fill="%238c4cf9" transform="rotate(90 50 50)"></path><path d="M50 50L20 50A30 30 0 0 0 80 50Z" fill="%238c4cf9"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;45 50 50;0 50 50" keyTimes="0;0.5;1"></animateTransform></path><path d="M50 50L20 50A30 30 0 0 1 80 50Z" fill="%238c4cf9"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;-45 50 50;0 50 50" keyTimes="0;0.5;1"></animateTransform></path></g></svg>');
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
}
.completed {
  background-color: $secondary-color;
  border-radius: 50%;
  color: $background-color;
  margin-right: 5px;
  padding: 5px;
}
.incomplete {
  background-color: $background-color;
  border-radius: 50%;
  border: 2px solid $border-color;
  color: $background-color;
  margin-right: 3px;
  padding: 3px;
}
.info-section {
  background-image: linear-gradient(to bottom right, $primary-button-text-color, $border-color);
  border-color: $tertiary-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  box-shadow: 5px 5px 10px $secondary-color;
  margin: 30px 0 0;
  padding: 40px 20px;
  width: 100%;
}
.info-section__sub-heading {
  color: $tertiary-color;
  font-size: 16px;
}
.info-section__sub-heading .fa {
  color: $quaternary-color;
  margin-right: 10px;
}
.info-section__content {
  margin-top: 10px;
}
.general-section {
  margin: 20px;
}
.general-section__heading {
  color: $tertiary-color;
  font-size: 20px;
}
.general-section__line {
  margin: 10px 0;
}
.language-test__label {
  font-weight: 700;
  margin-right: 5px;
}
.language-test__content {
  margin: 10px 0;
}
.language-test__icon {
  color: $quaternary-color;
  cursor: pointer;
}
.diff__added {
  color: green;
}
.diff__removed {
  color: red;
}
.form__select input {
  height: auto;
}
.form__message {
  margin: 10px 0;
}
.form__qr-image {
  height: 200px;
  width: 200px;
}
.form__link, .confirm-account__resend, .confirm-account__resend:visited, .form__link:visited, .personal-settings__photo-label, .work-settings__resume-label, .todo__link, .card__link {
  color: $tertiary-color;
  cursor: pointer;
}
.form__link:hover, .confirm-account__resend:hover, .personal-settings__photo-label:hover, .work-settings__resume-label:hover, .form__resume_label:hover, .todo__link, .card__link {
  color: $quaternary-color;
  cursor: pointer;
}
.mfa {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.5rem;
}
.mfa__section {
  border-bottom: 1px solid $primary-color;
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
}
.mfa__section-left {
  flex: 0 0 256px;
  max-width: 256px;
  object-fit: contain;
}
.mfa a {
  color: $quaternary-color;
}
.mfa a:hover {
  color: $tertiary-color;
}
.mfa__section-step {
  font-size: .9rem;
}
.mfa__section-title {
  font-weight: 600;
}
.mfa__section-list {
  margin-left: 1rem;
}
.mfa__section-no-scan {
  display: none;
}
.mfa__section-no-scan--show {
  display: block;
}
input.mfa__verification-code {
  width: 7rem;
}
.confirm-account__resend {
  font-weight: 600;
  color: $quaternary-color;
}
.confirm-account__resend:hover {
  font-weight: 600;
}
.form__field-icon {
  color: $secondary-border-color;
  float: right;
  margin-top: -1.5rem;
  margin-right: 0.65rem;
  position: relative;
  z-index: 2;
}
.todo__link {
  margin-left: 5px;
}
.work-settings__industries, .work-settings__job-attributes {
  flex-basis: 100px;
}
.work-settings__time-zone-id {
  margin-top: .25em;
  max-width: 416px;
}
.form__uploaded {
  margin-right: 10px;
}
.interest-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.interest-item__label {
  white-space: nowrap;
}
.multirange {
  display: flex;
  flex-basis: 100%;
}
.multirange__label {
  flex-basis: 100px;
  line-height: 20px;
}
.multirange__ranges {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-basis: calc(100% - 100px);
  gap: 10px;
}
.multirange__bar {
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 7px;
  display: flex;
  height: 14px;
  margin: 3px 0;
  overflow: hidden;
  width: 286px;
}
.multirange__bar-segment--full {
  background-color: $tertiary-color;
  height: 100%;
}
.multirange__range {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  gap: 10px;
}
.multirange__range-end, .multirange__range-start {
  flex: 0 0 130px;
}
.multirange__remove {
  border-color: $error-color;
  color: $error-color;
}
.form__description {
  height: 100px;
  max-width: 100%;
}
.form {
  background-image: linear-gradient(to bottom right, $primary-button-text-color, $border-color);
  border-color: $tertiary-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-top-width: 1px;
  border-left-width: 1px;
  box-shadow: 5px 5px 10px $secondary-color;
  padding: 20px;
  width: 100%;
}
.standard__line {
  margin: 10px 0;
}
.form__line {
  margin: 10px 0;
}
.form__sub-line {
  margin: 5px 0;
}
.form__error, .error-message {
  color: $error-color;
  margin: 20px 0;
}
.personal-settings__photo {
  display: none;
}
.personal-settings__birthday {
  flex-basis: 0;
  flex-grow: 1;
}
.work-settings__resume {
  display: none;
}
.error__line {
  display: inline-block;
  margin: 5px;
  width: 100%;
}
.form__inline-error {
  color: $error-color;
  display: inline-block;
  margin-top: 5px;
}
.form__line--flex {
  align-items: flex-start;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
.iti {
  width: 100%;
}
.form__same-line {
  float: left;
  margin-right: 10px;
}
.dropdown__display {
  width: 100%;
}
.dropdown__menu {
  width: 100%;
}
.dropdown__item {
  padding-left: 21px;
}
.dropdown__item:hover {
  background-color: $secondary-color;
}
.tooltip {
  background-color: $primary-button-text-color;
  border: 1px solid $tertiary-color;
  border-radius: 50%;
  color: $tertiary-color;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-left: 5px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}
.tooltip-text {
  background-color: $primary-button-text-color;
  border: 1px solid $tertiary-color;
  bottom: 10px;
  display: none;
  left: 10px;
  padding: 5px;
  position: absolute;
  text-align: left;
  width: 150px;
}
.tooltip:hover .tooltip-text {
  display: block;
}
.schedule {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.profile__img {
  height: 200px;
  max-width: 100%;
}
.experience {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  justify-content: space-between;
  line-height: 35px;
  margin-bottom: 5px;
  width: 100%;
}
input[type="number"].experience__years-of-experience {
  display: inline-block;
  margin: 0 5px;
  width: 70px;
}
.form__industry-ids, .form__job-attribute-ids {
  margin-bottom: 5px;
}
.experience__remove {
  color: $tertiary-color;
  cursor: pointer;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  margin-left: 3px;
  vertical-align: middle;
}
.payment-methods {
  margin-bottom: 10px;
}
.payment-method, .payment-method--title {
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding: 3px 0;
}
.payment-method--title {
  font-weight: 700;
}
.payment-method__type-and-nickname {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}
.payment-method__account-display {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 38%;
}
.payment-method__is-default {
  text-align: center;
  width: 6%;
}
.payment-method__is-default .processing {
  margin: 0 auto;
}
.payment-method__delete {
  text-align: center;
  width: 6%;
}
.payment-method__delete .processing {
  margin: 0 auto;
}
.payment-method__is-default.fa {
  color: $quaternary-color;
  cursor: pointer;
}
.payment-method__is-default.fa.fa-check-square {
  cursor: default;
}
.payment-method__delete.fa {
  color: $error-color;
  cursor: pointer;
}
.strikethrough {
  color: $secondary-border-color;
  text-decoration: line-through;
}
.body-wrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  width: 100%;
}
.nav {
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  background-image: linear-gradient(to right, $primary-color, $tertiary-color);
  color: $primary-button-text-color;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  transition: all 0.3s;
  width: 100%;
}
.nav-menu {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  position: relative;
}
.nav__item {
  color: $primary-button-text-color;
  cursor: pointer;
  display: block;
  padding: 10px;
  position: relative;
}
.nav__item-menu {
  background-image: linear-gradient(to right, $primary-color, $tertiary-color);
  color: $primary-button-text-color;
  display: none;
  left: 0;
  margin-top: 10px;
  max-width: 100%;
  min-width: 250px;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
}
.nav__item-menu-right {
  left: auto;
  right: 0;
}
.nav__item:hover .nav__item-menu {
  display: block;
}
.nav__sub-item {
  color: $primary-button-text-color;
  cursor: pointer;
  display: block;
  padding: 10px;
}
.nav__item-active {
  color: $secondary-color;
}
.nav__item:hover {
  background-color: $primary-button-hover-color;
  border-color: $secondary-color;
  border-style: solid;
  border-width: 1px 0px 0px;
  padding: 9px 10px 10px;
}
.nav__sub-item:hover {
  background-color: $primary-button-hover-color;
  border-color: $secondary-color;
  border-style: solid;
  border-width: 1px 0px;
  padding: 9px 10px;
}
.nav-icons {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  position: relative;
}
.nav-icon {
  color: $primary-button-text-color;
  cursor: pointer;
  padding: 10px;
}
.nav-tokens {
  display: inline;
  margin-left: 5px;
  max-width: 50px;
}
.nav-money {
  display: inline;
  margin-left: 5px;
  max-width: 100px;
}
.nav_active {
  margin-left: 0;
}
.content__no-auth {
  flex: 1 0 auto;
  margin-top: 50px;
}
.content__auth {
  flex: 1 0 auto;
}
.auth-content {
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  height: 100%;
  position: relative;
  transition: all 0.3s;
  width: 100%;
}
.content__container {
  align-items: flex-start;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
}
.content__container-full {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
}
.content__main {
  flex: 0 0 calc(60% - 20px);
}
.content__main--padded {
  padding: 20px;
}
.content__form {
  max-width: 600px;
  width: 100%;
}
.content__form-full {
  width: 100%;
}
.content__form.call-active, .content__form-full.call-active {
  padding-left: 10px;
}
.content__info, .content__info-full {
  background-color: $primary-button-text-color;
  border-color: $border-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  box-shadow: 5px 5px 10px $border-color;
  color: $tertiary-color;
  padding: 10px 20px;
  width: 100%;
}
.content__info-header-line {
  color: $quaternary-color;
  display: inline-block;
  font-size: 18px;
  margin: 10px 0;
  width: 100%;
}
.content__info-line {
  display: inline-block;
  margin: 5px 0;
  width: 100%;
}
.templates {
  display: none;
}
.content__heading {
  color: $quaternary-color;
  font-size: 20px;
  font-weight: 700;
}
.add-button {
  background-color: $quaternary-color;
  border-radius: 50%;
  color: $primary-button-text-color;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: -5px;
  vertical-align: middle;
  width: 40px;
}
.form__sub-heading {
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, $tertiary-color, $border-color);
  border-image-slice: 1;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.jobs, .jobs-active, .components, .components-active {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.job, .component {
  background-color: $primary-button-text-color;
  border-color: $border-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  box-shadow: 5px 5px 10px $border-color;
  min-width: 280px;
  padding: 20px;
  position: relative;
}
.job .component {
  align-self: stretch;
  background: #FFF;
}
.job {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  align-items: flex-start;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
}
.component {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  align-items: flex-start;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 0;
}
.work-job--enabled {
  border-color: #0f7eee;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-top-width: 2px;
  border-left-width: 2px;
  border-top-width: 1px;
  border-left-width: 1px;
  box-shadow: 5px 5px 10px #03d8ee;
}
.form__submit, .add-job__submit, .update-job__submit, .job__action, .add-component__submit, .update-component__submit, .component__action, .interview__start, .interview__test-call, .button--quaternary {
  background-color: $quaternary-color;
  border-radius: 16px;
  color: $primary-button-text-color;
  cursor: pointer;
  font-weight: 425;
  padding: 5px 16px;
  text-align: center;
}
.content__processing, .report-header__processing, .form__processing {
  display: block;
  height: 30px;
  width: 50px;
}
.component__pull-from-queue-processing {
  height: 15px;
}
.component__pull-from-queue-processing svg {
  position: relative;
  top: -8px;
}
.job__agent-statuses, .component__agent-statuses {
  margin-right: 15px;
}
.form__processing svg {
  position: relative;
  top: -8px;
}
.job__title, .component__name, .job__company-and-title {
  color: $tertiary-color;
  font-weight: 700;
}
.job__line, .component__line {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
}
.show-next {
  font-size: 14px;
}
.job__icon-line, .component__icon-line {
  color: $secondary-border-color;
  font-size: 14px;
}
.component__icon-history {
  color: $quaternary-color;
  cursor: pointer;
}
.job__description, .component__description, .job__company-description {
  font-size: 14px;
}
.job__line_column, .component__line_column {
  margin-right: 10px;
}
.job__line_label, .component__line_label {
  font-weight: 700;
  margin-right: 5px;
  white-space: nowrap;
}
.job__line_link, .component__line_link {
  color: $quaternary-color;
  cursor: pointer;
}
.job__description, .component__description {
  width: 100%;
}
.job__task, .component__task {
  bottom: 10px;
  color: $quaternary-color;
  cursor: pointer;
  font-size: 32px;
  position: absolute;
  right: 10px;
}
.job__status {
  color: $quaternary-color;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  right: 20px;
  text-align: right;
}
.job__company-description {
  margin-bottom: 10px;
}
.job__status-options {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: nowrap;
  height: 27px;
  justify-content: right;
  width: 100%;
}
.job__status-processing {
  height: 27px;
  text-align: right;
  width: 100%;
}
.job__status-option-decline {
  background-color: $error-color;
  border-radius: 16px 0 0 16px;
  color: #FFF;
  font-weight: 425;
  padding: 5px 16px;
}
.job__status-option-counter {
  background-color: $quaternary-color;
  color: #FFF;
  font-weight: 425;
  padding: 5px 16px;
}
.job__status-option-accept {
  background-color: $secondary-color;
  border-radius: 0 16px 16px 0;
  color: #FFF;
  font-weight: 425;
  padding: 5px 16px;
}
.job-status__job-invite {
  background-color: $primary-button-text-color;
  border-color: $border-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  box-shadow: 5px 5px 10px $border-color;
  color: $primary-color;
  font-weight: normal;
  min-width: 220px;
  max-width: 500px;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: left;
  z-index: 1;
}
.job-status__component {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: nowrap;
  height: 35px;
  justify-content: space-between;
  line-height: 35px;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}
.job-status__submit {
  margin: 10px 0;
  text-align: right;
}
.job-status__component-name {
  white-space: nowrap;
}
input[type="number"].job-status__component-agent-pay-amount {
  display: inline-block;
  margin-left: 10px;
  width: 75px;
}
.component__schedule-dow {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
}
.component__schedule {
  font-size: 14px;
  width: 100%;
}
.component__schedule-dow-description {
  font-weight: 700;
  width: 90px;
}
.component__schedule-time-title {
  height: 12px;
  overflow: hidden;
  width: 100%;
}
.component__schedule-time-title-range {
  display: inline-block;
  height: 12px;
  width: calc(100% / 12);
}
.component__schedule-time {
  border: 1px solid $border-color;
  border-radius: 6px;
  height: 12px;
  overflow: hidden;
  width: 100%;
}
.component__schedule-time-range.active {
  background-color: $quaternary-color;
}
.component__schedule-time-range {
  display: inline-block;
  height: 12px;
  width: calc(100% / 96);
}
.clickable {
  color: $tertiary-color;
  cursor: pointer;
  font-weight: bold;
}

.role-hidden {
  display: none !important;
}
.is-hidden {
  display: none !important;
}
.is-invisible {
  opacity: 0 !important;
  -webkit-transition: opacity 2s;
  -moz-transition: opacity 2s;
  -o-transition: opacity 2s;
  transition: opacity 2s;
}
.is-visible {
  opacity: 1 !important;
  -webkit-transition: opacity 2s;
  -moz-transition: opacity 2s;
  -o-transition: opacity 2s;
  transition: opacity 2s;
}
.input-error{
  box-shadow: 0 0 3px $error-color;
}
.test__buttons {
  margin-top: 10px;
}
.language-test__buttons, .language-test__processing {
  margin-top: 10px;
}
.language-test__text-area {
  height: 300px;
  width: 100%;
}
.language__row, .language__header {
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: nowrap;
  height: 50px;
  text-align: center;
}
.language__header {
  color: $tertiary-color;
}
.language__row:hover {
  background-color: rgba(255, 255, 255, 0.3)
}
.language__column-header {
  border-right: 1px solid $tertiary-color;
  line-height: 50px;
  padding-right: 15px;
  text-align: right;
  width: 25%;
}
.language__column {
  width: 25%;
}
.language__column:hover {
  background-color: rgba(255, 255, 255, 0.3)
}
.language__link {
  color: $quaternary-color;
  display: block;
  margin: 0 auto;
}
.language__result {
  color: $secondary-color;
  cursor: default;
  display: inline-block;
  margin: 0 auto;
}
.typing-speed__area {
  margin-top: 10px;
}
.typing-speed__results-header {
  border-image: linear-gradient(to right, $tertiary-color, $border-color);
  border-image-slice: 1;
  border-top: 1px solid;
  font-weight: 700;
  margin-top: 10px;
  padding-top: 10px;
}
.typing-speed__display {
  float: left;
  margin: 5px 0;
  width: 25%;
}
.typing-speed__timer, .typing-speed__wpm, .typing-speed__errors, .typing-speed__cpm {
  font-size: 30px;
}
.typing-speed__history {
  color: transparent;
}
.typing-speed__content {
  font-family: Roboto Mono;
  font-size: 30px;
  text-align: left;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.typing-speed__good-char {
  color: rgba(30, 144, 255, 0.5);
}
.typing-speed__bad-char {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 0, 0, 0.2);
}
.typing-speed__current-char {
  background-color:rgba(30, 144, 255,0.2);
}
.typing-speed__current-char--off {
  background-color:inherit;
}
.typing-speed__content {
  color: rgba(0, 0, 0, 0.3);
}
.typing-speed__scroll {
  height: auto;
  padding-bottom: 0;
  padding-top: 0;
  visibility: hidden;
}
.typing-speed__input, .typing-speed__history, .typing-speed__scroll, .typing-speed__content {
  font-family: Roboto Mono;
  font-size: 30px;
  text-align: left;
  width: 100%;
}
.typing-speed__input, .typing-speed__history, .typing-speed__scroll {
  position: absolute;
}
.typing-speed__input {
  color: transparent; 
  height: 3104px;
  outline: transparent solid 0px;
}
.report {
  border-color: $tertiary-color;
  border-style: solid;
  border-width: 2px;
  margin: 10px 0 20px;
}
.report-form {
  background-image: linear-gradient(to bottom right, $primary-button-text-color, $border-color);
  border-color: $tertiary-color;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-top-width: 1px;
  border-left-width: 1px;
  box-shadow: 5px 5px 10px $secondary-color;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0 20px;
  padding: 10px;
  width: auto;
}
.report-header input {
  width: auto;
}
.report-header__line {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.report-header__line--submit {
  align-items: center;
}
.report-header__submit {
  background-color: $quaternary-color;
  border-radius: 16px;
  color: #FFF;
  font-weight: 425;
  padding: 5px 16px;
}
.report__calendar {
  background-color: $background-color;
}
.report__date-label, .report__compare-date-label {
  padding: 0;
}
.report__date, .report__compare-date {
  width: 200px;
}
.calendar__day, .calendar__day:hover {
  border: 1px solid $background-color;
  border-radius: 0;
  box-sizing: initial;
  margin: 0;
  padding: 0.166em;
}
.calendar__day.react-datepicker__day--selected {
  background-color: $tertiary-color;
}
.calendar__day.react-datepicker__day--in-selecting-range {
  background-color: $tertiary-color;
}
.calendar__day.react-datepicker__day--today {
  border: 1px solid $primary-color;
}

.report .google-visualization-table-table {
  color: $primary-color;
}
.report .google-visualization-table-table a {
  color: $tertiary-color;
}
.report .google-visualization-table-tr-head {
  background-color: $primary-color;
  color: #FFF;
}
.report-toolbar {
  margin-top: 10px;
}
.report-toolbar .goog-menu-button {
  background: none;
}
.report-toolbar .goog-menu-button-outer-box, .goog-menu-button-inner-box {
  border-style: none;
}
.report-toolbar__next, .report-toolbar__prev {
  float: left;
}
.report-toolbar__export {
  float: right;
}
.report-toolbar__next, .report-toolbar__prev, .report-toolbar__export .goog-menu-button-inner-box {
  background-color: $quaternary-color;
  border-style: none;
  border-radius: 16px;
  color: #FFF;
  cursor: pointer;
  font-weight: 425;
  padding: 5px 16px;
}
.switch {
  display: inline-block;
  height: 28px;
  position: relative;
  width: 50px;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  -webkit-transition: .4s;
  background-color: $border-color;
  border-radius: 28px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  -webkit-transition: .4s;
  background-color: $quaternary-color;
  border-radius: 50%;
  bottom: 3px;
  content: "";
  height: 22px;
  left: 3px;
  position: absolute;
  transition: .4s;
  width: 22px;
}
.job .switch {
  position: absolute;
  right: 10px;
  top: 10px;
}

input:checked + .slider {
  background-color: $quaternary-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $quaternary-color;
}

input:checked + .slider:before {
  background-color: $primary-button-text-color;
  -ms-transform: translateX(22px);
  -webkit-transform: translateX(22px);
  transform: translateX(22px);
}
.player {
  margin-left: 270px;
}
.player__message {
  height: 20px;
  text-align: center;
}
.player__controls {
  padding: 8px;
}
.player__button {
  background: transparent;
  border: none;
  color: $quaternary-color;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 3px 0 0 0;
  outline: none;
  text-align: center;
  width: 20px;
}
.player__track {
  border: 1px solid $border-color;
  display: inline-block;
  height: 5px;
  margin: 16px 0 0 16px;
  position: relative;
  vertical-align: top;
  width: 236px;
}
.player__progress {
  background: $secondary-color;
  height: 100%;
  width: 0%;
  position: absolute;
}
.player__scrubber {
  background: $quaternary-color;
  border: solid 3px $border-color;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  margin: -7px 0 0 -9px;
  position: absolute;
  width: 18px;
}
.player__transcription {
  margin-top: 50px;
}
.player__link {
  color: $quaternary-color;
  cursor: pointer;
  font-weight: 700;
  margin-top: 50px;
}
.info-window__content {
  background: white;
  margin: 0 auto;
  overflow-y: scroll;
  padding: 20px;
  position: relative;
  z-index: 101;
}
.info-window {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.info-window__background {
  background-color: $border-color;
  bottom: 0;
  left: 0;
  opacity: 90%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.info-window__close {
  color: $quaternary-color;
  cursor: pointer;
  font-family: courier new;
  font-weight: 700;
  height: 20px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 5px;
  vertical-align: middle;
  width: 20px;
}
.info-window__content--narrow {
  min-height: auto;
  top: 30%;
}
.token-game {
  display: flex;
  flex-wrap: wrap;
}
.report-data-grid {
  width: 100%;
  height: 600px;
}
.conf10 { 
  color: #000;
}
.conf9 { 
  color: #111;
}
.conf8 { 
  color: #222;
}
.conf7 { 
  color: #333;
}
.conf6 { 
  color: #666;
}
.conf5 { 
  color: #777;
}
.conf4 { 
  color: #888;
}
.conf3 { 
  color: #999;
}
.conf2 { 
  color: #999;
}
.conf1 { 
  color: #AAA;
}
.conf0 { 
  color: #AAA;
}
.outbound-component {
  position: absolute;
  right: 10px;
  top: 50px;
  width: 220px;
}
input.outbound__phone-number {
  display: inline-block;
  margin: 2px 5px 2px 0;
  width: 155px;
}
.outbound__dial {
  background-color: $quaternary-color;
  border-radius: 30px;
  color: $background-color;
  font-size: 20px;
  height: 39px;
  text-align: center;
  width: 39px;
}
.outbound__processing {
  height: 39px;
  padding-top: 5px;
  width: 39px;
}
.outbound__processing svg {
  left: -5px;
  position: relative;
}
.component__calls {
  border: 1px solid $border-color;
  font-size: 14px;
  padding: 10px;
}
.component__call {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.form .ss-main {
  margin-top: 5px;
}
.form .ss-main .ss-multi-selected {
  border-color: gray;
  min-height: 35px;
}
.form .ss-main .ss-multi-selected .ss-add {
  margin-top: 11px;
}
.form .ss-main .ss-multi-selected .ss-values .ss-value {
  background-color: $tertiary-color;
}
.call-real-time {
  padding-top: 32px;
  position: relative;
}
.transcription {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: scroll;
  width: 100%;
}
.transcription-line {
  margin: 5px 0;
  width: 100%;
}
.transcription-line-agent {
  text-align: right;
}
.transcription-line-customer {
  text-align: left;
}
.transcription-bubble {
  display: inline-block;
  max-width: calc(100% - 10px);
  padding: 10px;
  text-align: left;
}
.transcription-bubble-customer {
  background-color: $background-color;
  border-radius: 0 10px 10px 0;
  color: $tertiary-color;
  margin-right: 10px;
}
.transcription-bubble-agent {
  background-color: $tertiary-color;
  border-radius: 10px 0 0 10px;
  color: $background-color;
  margin-left: 10px;
}
.customer-sentiment {
  left: 0;
  position: absolute;
  top: 0;
}
.agent-sentiment {
  position: absolute;
  right: 36px;
  top: 0;
}
.sentiment {
  font-size: 36px;
  position: absolute;
}
.sentiment.fa-angry {
  color: red;
}
.sentiment.fa-frown {
  color: orange;
}
.sentiment.fa-meh {
  color: yellow;
}
.sentiment.fa-smile {
  color: yellowgreen;
}
.sentiment.fa-smile-beam {
  color: green;
}
.blink-disappear {
  animation: blink-disappear normal 3s 1 linear forwards;
  animation-fill-mode: forwards;
}
.blink-green {
  animation: blink-green normal 1s 5 ease-in-out;
}
@keyframes blink-green {
  from {
    color: $primary-button-text-color;
  }
  to {
    color: #0f0;
  }
}
@keyframes blink-disappear {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: $screen-sm) {
  .home-qa__title {
    font-size: 24px;
  }
  .work {
    font-size: 36px;
  }
  .work-adverb {
    width: 115px;
  }
  .home-pay__title {
    font-size: 26px;
    line-height: 30px;
  }
  .home-reasons__title {
    font-size: 34px;
    line-height: 38px;
  }
  .home-reason__title {
    font-size: 20px;
  }
  .home-intro__title {
    font-size: 30px;
  }
  .home-summary__title {
    font-size: 34px;
  }
  .home-how__title {
    font-size: 34px;
  }
  .home-how__sub-title {
    font-size: 24px;
  }
  .home-how-card__title {
    font-size: 36px;
  }
}
@media (min-width: $screen-md) {
  .form__sub-section {
    margin-left: 10px;
  }
  .home-intro__title {
    font-size: 36px;
  }
  .home-content__bubble {
    border-style: solid;
    border-radius: 10px;
    border-width: 0 2px 2px 0;
    padding: 20px;
    width: 450px;
  }
  .home-content__bubble-1 {
    border-color: $tertiary-color;
    box-shadow: 5px 5px 10px $secondary-color;
  }
  .home-content__bubble-2 {
    border-color: $tertiary-color;
    border-width: 1px 2px 2px 1px;
    box-shadow: 5px 5px 10px $quaternary-color;
  }
  .home-content__bubble-3 {
    border-color: $tertiary-color;
    box-shadow: 5px 5px 10px $background-color;
  }
  .home-intro-content__title {
    font-size: 28px;
  }
}
@media (min-width: $screen-md-lg) {
  .slideshow__img--1 {
    background-image: url('/images/slideshow1.png');
  }
  .slideshow__img--2 {
    background-image: url('/images/slideshow2.png');
  }
  .slideshow__img--3 {
    background-image: url('/images/slideshow3.png');
  }
  .slideshow__img--4 {
    background-image: url('/images/slideshow4.png');
  }
  .slideshow__img--5 {
    background-image: url('/images/slideshow5.png');
  }
  .work {
    font-size: 60px;
  }
  .work-adverb {
    width: 195px;
  }
  .home-intro__title {
    font-size: 48px;
  }
  .home-reason__title {
    font-size: 24px;
  }
  .home-reasons__title {
    font-size: 48px;
    line-height: 52px;
  }
  .home-video__question {
    font-size: 28px;
    line-height: 32px;
  }
  .home-how__title {
    font-size: 48px;
    margin-bottom: 52px;
  }
  .home-pay__title {
    font-size: 40px;
    line-height: 44px;
  }
  .home-summary__title {
    font-size: 60px;
  }
}
@media (min-width: $screen-lg) {
  .general-content__title {
    font-size: 24px;
  }
  .general-content__small-title {
    font-size: 20px;
  }
  .info-section {
    margin: 50px 0 0;
  }
  .info-section__sub-heading {
    font-size: 20px;
  }
  .info-section {
    width: 45%;
  }
  .tooltip-text {
    white-space: nowrap;
    width: auto;
  }
  .content__info {
    width: 40%;
  }
  .content__form {
    width: 55%;
  }
  .component {
    width: 48%;
  }
  .home-intro__container {
    padding: 15px;
  }
  .home-intro-content {
    border-color: $tertiary-color;
    border-style: solid;
    border-radius: 10px;
    border-width: 0 2px 2px 0;
    box-shadow: 5px 5px 10px $secondary-color;
  }
  .work {
    font-size: 72px;
  }
  .work-adverb {
    width: 220px;
  }
  .home-reasons__title {
    font-size: 40px;
    line-height: 44px;
  }
  .home-content-main__description {
    font-size: 20px;
  }
  .home-intro__container {
    align-items: initial;
    display: flex;
    padding: 80px 20px;
  }
  .home-how__container {
    margin: 40px 0;
  }
  .home-how__title {
    font-size: 60px;
    margin-bottom: 60px;
  }
  .home-how__sub-title {
    font-size: 36px;
  }
  .home-how-card__title {
    font-size: 48px;
  }
  .home-intro-opportunities {
    justify-content: space-around;
  }
  .home-testimonials {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .home-testimonial {
    background-color: $background-color;
    overflow: hidden;
    position: relative;
    max-width: 25%;
    width: 200px;
  }
  .home-testimonial__img {
    height: 200px;
    width: 100%;
  }
  .home-testimonial__description {
    color: $secondary-border-color;
    font-style: italic;
    height: 200px;
    padding: 20px;
  }
  .home-testimonial__description::before {
    border-left: 25px $background-color solid;
    border-bottom: 19px transparent solid;
    border-top: 19px transparent solid;
    content: '';
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(-1px,-50%,0);
    transform: translate3d(-1px,-50%,0);
    z-index: 2;
  }
  .home-pay-card__content--left {
    flex-direction: row;
  }
  .home-pay-card__content--right {
    flex-direction: row;
  }
  .home-content-main {
    margin-top: -160px;
  }
  .home-containers {
    gap: 0;
  }
  .home-pay__img {
    min-width: 300px;
    max-width: 480px;
  }
  .home-qa__title {
    font-size: 36px;
  }
  .home-qa__question {
    width: 48%;
  }
  .home-qa__description {
    flex-direction: row;
  }
}
@media (min-width: $screen-xlg) {
  .home-content__bubble-2.reveal.fade.active {
    animation: fade 1s ease-in;
  }
  .home-content__bubble-3.reveal.fade-right.active {
    animation: fade-right 1s ease-in;
  }
  .home-content__bubble {
    width: 300px;
  }
  .home-intro-opportunities {
    align-items: initial;
    flex-direction: row;
  }
  .home-testimonial {
    width: 280px;
  }
  .home-testimonial__img {
    height: 280px;
  }
  .home-testimonial__description {
    height: 280px;
  }
  .home-overview__reasons {
    flex-direction: row;
  }
  .home-reasons {
    width: 50%;
  }
  .home-video {
    width: 50%;
  }
  .home-how__container {
    height: 100vh;
    margin: 0;
    min-height: 800px;
    justify-content: center;
  }
  .home-how-cards {
    flex-direction: row;
    gap: initial;
    padding: 0 20px;
  }
  .home-how-connect {
    border-width: 0 0 2px;
    box-shadow: 5px 5px 10px $secondary-color;
    height: 10px;
    left: 0;
    margin-left: 12%;
    top: 145px;
    width: 76%;
  }
  .home-pay-card__content--left {
    width: 80%;
  }
  .home-pay-card__content--right {
    width: 80%;
  }
}
@media (min-width: $screen-xxlg) {
  .home-reasons__title {
    font-size: 48px;
    line-height: 52px;
  }
  .home-how-cards {
    padding: 0 7.5%;
  }
}
@media (min-width: $screen-xxxlg) {
  .home-pay-card__content--left {
    border-radius: 20px;
  }
  .home-pay-card__content--right {
    border-radius: 20px;
  }
  .home-intro__container {
    margin: 0 auto;
    max-width: 1400px;
  }
  .home-overview__container {
    margin: 0 auto;
    max-width: 1400px;
  }
  .home-how__container {
    margin: 0 auto;
    max-width: 1400px;
  }
  .home-pay__container {
    margin: 0 auto;
    max-width: 1400px;
  }
  .home-summary__container {
    margin: 0 auto;
    max-width: 1400px;
  }
  .home-qa {
    margin: 0 auto;
    max-width: 1400px;
  }
  .general-content {
    margin: 20px auto;
    max-width: 1200px;
  }
  .home-pay__title {
    font-size: 48px;
    line-height: 52px;
  }
}
